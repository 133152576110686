import React, { useEffect, useState } from 'react';
import { Divider, Typography } from '@material-ui/core';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import { Autocomplete } from '@material-ui/lab';
import useDataService from '../../hooks/useDataService';
import apiToUrlMap from '../../ApiMapping';
import { eMessageType } from '../../types/IMessageType';
import { BackButton, PrimaryButton, TextField } from '../../components/AtomComponents';

const defaultSelectedRepositories = ['phonex-buyer'];

type tenantInfoType = {
  tenantId: string;
  language: string;
  locale: string;
};
// type syncStatusType = {
//   isSynced: boolean;
//   translations?: { add: []; update: []; error: {} };
// };
type syncTenantsType = {
  tenants: { add: []};
  invalidTenants:[]
};

type syncNewTranslatedkeysType = {
  isSynced: boolean;
  invalidTenants:[];
  translations: { newI18nKeys: []; error: {} };
};

type translateNewTenantsType = {
  tenantsTranslated:[];
  invalidTenants:[];
  translations: { error: {} };
  isTranslated: boolean;
}

type updateDbType = {
  isUpdated: boolean;
  updatedRecords?: [];
  deletedRecords?: [];
};

const TranslationAutomation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [repositories, setRepositories] = useState([]);
  const [selectedRepositories, setSelectedRepositories] = useState<
    { name: string; uuid: string; slug: string }[]
  >([]);
  const { openSnackBar, fetchUrl } = useDataService();

  const [tenantsData, setTenantsData] = useState<tenantInfoType[]>([]);
  const [selectedTenants, setSelectedTenants] = useState<tenantInfoType[]>([]);
  const [sheetInfo, setSheetInfo] = useState<{ spreadsheetId: string; sheetName: string }>({
    spreadsheetId: '',
    sheetName: '',
  });
  const [sheetSyncTenantsData, setSheetSyncTenantsData] = useState<syncTenantsType | undefined>();
  const [sheetSyncNewKeysTranslatedData, setSheetSyncNewKeysTranslatedData] = useState<syncNewTranslatedkeysType | undefined>();
  const [sheetSyncNewTenantsTranslatedData, setSheetSyncNewTenantsTranslatedData] = useState<translateNewTenantsType| undefined>();
  const [updateDbData, setUpdateDbData] = useState<updateDbType>();

  async function fetchRepositories() {
    const repoResults = await fetchUrl('GET', apiToUrlMap.getBitbucketRepos);
    const repos = repoResults.values.map((repo: { name: string; slug: string; uuid: string }) => ({
      name: repo.name,
      slug: repo.slug,
      uuid: repo.uuid,
    }));
    const preselectedRepositories: { name: string; uuid: string; slug: string }[] = [];
    repos.forEach((repo: { name: string; slug: string; uuid: string }) => {
      if (defaultSelectedRepositories.includes(repo.slug)) preselectedRepositories.push(repo);
    });
    setRepositories(repos);
    setSelectedRepositories(preselectedRepositories);
  }

  async function fetchTenantsData() {
    const { tenantsData } = await fetchUrl('GET', apiToUrlMap.getTenantLanguages);
    if (tenantsData?.length) {
      setTenantsData(tenantsData);
      const nonEnglishTenants = tenantsData.filter((tenant: tenantInfoType) => {
        return !tenant.locale.includes('en');
      });
      setSelectedTenants(nonEnglishTenants);
    }
  }

  async function fetchSheetInfo() {
    const data = await fetchUrl('GET', apiToUrlMap.getSheetInfo);
    if (data) {
      setSheetInfo(data);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await Promise.all([fetchRepositories(), fetchTenantsData(), fetchSheetInfo()]);
      } catch (e: any) {
        openSnackBar(e.message || 'Something went wrong, Unable to fetch data', eMessageType.error);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function downloadFile(res: unknown) {
    const fileName = 'i18n-keys';
    const json = JSON.stringify(res);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  async function downloadi18nKeys() {
    setIsLoading(true);
    const res = await fetchUrl('GET', apiToUrlMap.getTranslationSheetValues);
    await downloadFile(res);
    openSnackBar(
      'i18n keys available in the sheet are downloaded successfully.',
      eMessageType.success
    );
    setIsLoading(false);
  }

  // const syncTenantsHandler = async () => {
  //   try {
  //     if (!selectedRepositories.length) {
  //       openSnackBar(
  //         'You need to select atleast one repository to fetch I18n keys',
  //         eMessageType.error
  //       );
  //       return;
  //     }
  //     setIsLoading(true);
  //     setSheetSyncData({ isSynced: true });
  //     const {
  //       isSynced,
  //       tenants,
  //       translations: { add, update, message },
  //     } = await fetchUrl('POST', apiToUrlMap.syncTenantsAndTranslationKeys, {
  //       body: {
  //         selectedRepositories,
  //       },
  //     });
  //     if (isSynced) {
  //       openSnackBar('Spreadsheet already synced', eMessageType.success);
  //     } else if (message) {
  //       openSnackBar(message, eMessageType.warning);
  //     } else if (isSynced === false) {
  //       openSnackBar('Spreadsheet synced successfully', eMessageType.success);
  //       setSheetSyncData({ isSynced, tenants, translations: { add, update } });
  //     }
  //   } catch (error: any) {
  //     openSnackBar(
  //       error.message || 'Something went wrong, Unable to translate I18n keys',
  //       eMessageType.error
  //     );
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const syncTenantsHandler = async () => {
    try {
      setIsLoading(true);
      const {
        tenants,
        invalidTenants,
      } = await fetchUrl('POST', apiToUrlMap.syncTenants);
      setSheetSyncNewKeysTranslatedData(undefined)
      setSheetSyncNewTenantsTranslatedData(undefined)
      if (!tenants.add.length) {
        openSnackBar('Spreadsheet already synced', eMessageType.success);
      } else {
        openSnackBar('Spreadsheet synced successfully', eMessageType.success);
      }
      setSheetSyncTenantsData({ tenants, invalidTenants });
    } catch (error: any) {
      openSnackBar(
        error.message || 'Something went wrong, Unable to translate I18n keys',
        eMessageType.error
      );
    } finally {
      setIsLoading(false);
    }
  }

  const translateNewTenantsHandler = async()=>{
    try {
      if (!selectedRepositories.length) {
        openSnackBar(
          'You need to select atleast one repository to fetch I18n keys',
          eMessageType.error
        );
        return;
      }
      setIsLoading(true);
      const { tenantsTranslated,invalidTenants,translations, isTranslated } = await fetchUrl('POST', apiToUrlMap.translateNewTenants, {
        body: {
          selectedRepositories,
        },
      });
      setSheetSyncTenantsData(undefined)
      setSheetSyncNewKeysTranslatedData(undefined)
      if(isTranslated){
        openSnackBar('Tenants already translated', eMessageType.success);
      }else{
        openSnackBar('Tenants translated successfully', eMessageType.success);
      }
      setSheetSyncNewTenantsTranslatedData({ tenantsTranslated,invalidTenants,translations, isTranslated })
    } catch (error:any) {
      openSnackBar(
        error.message || 'Something went wrong, Unable to translate I18n keys',
        eMessageType.error
      );
    }finally{
      setIsLoading(false);
    }
  }

  const syncNewKeysAndTranslateHandler = async () => {
    try {
      if (!selectedRepositories.length) {
        openSnackBar(
          'You need to select atleast one repository to fetch I18n keys',
          eMessageType.error
        );
        return;
      }
      setIsLoading(true);
      const { isSynced,invalidTenants, translation: { newI18nKeys, error } } = await fetchUrl('POST', apiToUrlMap.translateNewKeys, {
        body: {
          selectedRepositories,
        },
      });

      setSheetSyncTenantsData(undefined)
      setSheetSyncNewTenantsTranslatedData(undefined)
      if (isSynced) {
        openSnackBar('Spreadsheet already synced', eMessageType.success);
      } else {
        openSnackBar('Spreadsheet synced successfully', eMessageType.success);
      }
      setSheetSyncNewKeysTranslatedData({ isSynced,invalidTenants, translations: { newI18nKeys, error } });
    } catch (error: any) {
      openSnackBar(
        error.message || 'Something went wrong, Unable to translate I18n keys',
        eMessageType.error
      );
    } finally {
      setIsLoading(false);
    }
  }

  const updateHandler = async () => {
    try {
      if (!selectedTenants.length) {
        openSnackBar(
          'You need to select atleast one tenant to update the database',
          eMessageType.error
        );
        return;
      }
      setIsLoading(true);
      setUpdateDbData({ isUpdated: false });

      const { isUpdated, updatedRecords, deletedRecords, message } = await fetchUrl(
        'PUT',
        apiToUrlMap.updateI18nTable,
        {
          body: {
            selectedTenants,
          },
        }
      );

      if (isUpdated) {
        openSnackBar('Database synced successfully', eMessageType.success);
        setUpdateDbData({ isUpdated, updatedRecords, deletedRecords });
      } else if (message) {
        openSnackBar(message, eMessageType.warning);
      } else {
        openSnackBar('No record updated', eMessageType.success);
      }
    } catch (error: any) {
      openSnackBar(
        error.message || 'Something went wrong, Unable to Update I18n data',
        eMessageType.error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const TenantList = ({ title, tenants }:any) => (
    tenants?.length ? (
      <div className="grid-x">
        <div className="margin-bottom-1 cell small-10 large-5">
          <Typography variant="body1" component="h5">
            {`${title}: ${tenants.join(', ')}`}
          </Typography>
        </div>
      </div>
    ) : null
  );

  const TranslationErrors = ({ errors }:any) => (
    errors ? (
      <div>
        <Typography variant="h6" component="h4">
          Failed Translated Keys
        </Typography>
        {Object.entries(errors).map(([language, keys]:any) =>
          keys.length ? (
            <div key={language} className="margin-bottom-2">
              <Typography variant="body1" component="h3">
                Language({language})
              </Typography>
              <div className="padding-left-2">
                {keys.map((key:any, index:any) => (
                  <Typography variant="body2" component="p" key={index}>
                    {key}
                  </Typography>
                ))}
              </div>
            </div>
          ) : null
        )}
      </div>
    ) : null
  );
  

  return (
    <>
      <LoadingDialog isDialogOpen={isLoading} />
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12">Translation Automation</h3>
      </div>
      <div className="margin-vertical-2">
        <Typography gutterBottom variant="body1" component="h5" className="margin-bottom-2">
          Click on the download button to download all the keys present in the master sheet. The
          downloaded file should be then used in the translation file on the buyer. <br />
          Note: Your repository might already be updated. Download the file and contact developer.
        </Typography>
        <PrimaryButton onClick={downloadi18nKeys}>DOWNLOAD i18n KEYS</PrimaryButton>
      </div>
      <Divider />
      <div className="margin-vertical-2">
        <Typography gutterBottom variant="body1" component="h5" className="margin-bottom-2">
          Click on the SYNC button to add the newly onboarded tenants and to translate I18n keys of
          non-english tenants
        </Typography>
        <div className="grid-x">
          <div className="margin-bottom-2 margin-right-1 cell small-10 large-5">
            <TextField
              fullWidth
              variant="outlined"
              label="Spreadsheet ID"
              value={sheetInfo?.spreadsheetId}
              disabled
            />
          </div>
          <div className="margin-bottom-2 cell small-10 large-2">
            <TextField
              fullWidth
              variant="outlined"
              label="Sheet Name"
              value={sheetInfo?.sheetName}
              disabled
            />
          </div>
        </div>
        <div className="grid-x">
          <div className="margin-bottom-2 cell small-10 large-5">
            <Autocomplete
              multiple
              id="repositories"
              value={selectedRepositories}
              limitTags={3}
              options={repositories}
              getOptionDisabled={(option) => !defaultSelectedRepositories.includes(option.slug)}
              getOptionLabel={(option: { name: string; uuid: string; slug: string }) => option.name}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select Repositories" />
              )}
              fullWidth
              onChange={(_event, value) => setSelectedRepositories(value)}
            />
          </div>
        </div>
        <div className="grid-x">
          <div className="margin-right-1 cell shrink">
            <PrimaryButton onClick={syncTenantsHandler}>
              SYNC TENANTS FROM DB
            </PrimaryButton>
          </div>
          <div className="margin-right-1 cell shrink">
            <PrimaryButton onClick={translateNewTenantsHandler}>
              TRANSLATE NEW TENANTS
            </PrimaryButton>
          </div>
          <div className="cell shrink">
            <PrimaryButton onClick={syncNewKeysAndTranslateHandler}>
              SYNC NEW I18N KEYS FROM CODE AND TRANSLATE
            </PrimaryButton>
          </div>
        </div>

        {sheetSyncTenantsData?.tenants?.add?.length ? (
          <>
            <TenantList
              title="New tenants added"
              tenants={sheetSyncTenantsData.tenants.add}
            />
            <TenantList
              title="Invalid Tenants"
              tenants={sheetSyncTenantsData.invalidTenants?.map(({ tenantId }) => tenantId)}
            />
          </>
        ):<></>}

        {sheetSyncNewTenantsTranslatedData?.isTranslated === false ? (
          <>
          <div className="grid-x">
            <div className="margin-top-2 margin-bottom-1 cell small-10 large-5 stats-container">
              <Typography variant="h5" component="h2">Translation Stats</Typography>
            </div>
          </div>
            <TenantList
              title="Newly Translated Tenants"
              tenants={sheetSyncNewTenantsTranslatedData.tenantsTranslated}
            />
            <TranslationErrors errors={sheetSyncNewTenantsTranslatedData?.translations?.error} />
          </>
        ):<></>}

        {sheetSyncNewKeysTranslatedData?.isSynced === false ? (
          <>
            <div className="grid-x">
              <div className="margin-top-2 margin-bottom-1 cell small-10 large-5 stats-container">
                <Typography variant="h5" component="h2">Translation Stats</Typography>
              </div>
            </div>
            <TenantList
              title="Translation keys added"
              tenants={[sheetSyncNewKeysTranslatedData?.translations?.newI18nKeys?.length]}
            />
            <TranslationErrors errors={sheetSyncNewKeysTranslatedData?.translations?.error} />
          </>
        ):<></>}
      </div>
      <Divider />
      <div className="margin-vertical-2">
        <Typography gutterBottom variant="body1" component="h5">
          Select the tenants with their language and hit Update I18N Config
        </Typography>

        <div className="grid-x">
          <div className="margin-top-1 margin-bottom-2 cell small-10 large-5">
            <Autocomplete
              multiple
              options={tenantsData}
              value={selectedTenants}
              getOptionLabel={(option) => `${option.tenantId} - ${option.language}`}
              filterSelectedOptions
              onChange={(event, newValue: any) => {
                setSelectedTenants(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Tenant"
                  placeholder={selectedTenants.length !== tenantsData.length ? 'Add' : 'No options'}
                />
              )}
            />
          </div>
        </div>
        <PrimaryButton onClick={updateHandler}>Update I18N Config</PrimaryButton>
        {updateDbData?.isUpdated === true && (
          <>
            <div className="grid-x">
              <div className="margin-top-2 margin-bottom-1 cell small-10 large-5 stats-container">
                <Typography variant="h6" component="h6">
                  Update Config Stats
                </Typography>
              </div>
            </div>
            {updateDbData?.updatedRecords?.length ? (
              <div className="grid-x">
                <div className="margin-bottom-1 cell small-10 large-5">
                  <Typography variant="body1" component="h5">
                    {'I18n keys updated: ' + updateDbData.updatedRecords.length}
                  </Typography>
                </div>
              </div>
            ) : (
              <></>
            )}
            {updateDbData?.deletedRecords?.length ? (
              <div className="grid-x">
                <div className="margin-bottom-1 cell small-10 large-5">
                  <Typography variant="body1" component="h5">
                    {'I18n keys deleted: ' + updateDbData.deletedRecords.length}
                  </Typography>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TranslationAutomation;
