import { specialSymbols } from './_lib/lib';

const apiToUrlMap = {
  // apiName: apiName,
  //reseller invite
  verifyInvitation: '/pxns/invitation/verify',
  locateTenant: '/pxns/invitation/locate-tenant',
  acceptInvitation: '/pxns/invitation/accept',
  // user information
  getUserInfo: '/px-api-gateway/account/user-info',
  getPhonexPolicies: '/px-api-gateway/module-contracts/current-tenant-contract',
  // bulk channel update
  bulkUpdate: '/px-api-gateway/inventory/items/bulk-update',
  updateQuantityChannel: '/px-api-gateway/inventory/item/update-quantity-channel',
  // inventory
  inventoryItems: '/px-api-gateway/inventory/items',
  // inventory import export
  inventoryExport: '/px-api-gateway/inventory/export',
  inventoryImport: '/px-api-gateway/inventory/import-inventory-file',
  inventoryImportAccept: '/px-api-gateway/inventory/import-inventory-file/accept',
  // buyer CRUD
  getCustomers: '/px-api-gateway/account/buyers',
  getCustomer: '/px-api-gateway/account/buyer/{buyerId}',
  createCustomer: '/px-api-gateway/account/buyer',
  updateCustomer: '/px-api-gateway/account/buyer/{buyerId}',
  // activate and deactivate customer
  activateCustomer: '/px-api-gateway/account/buyer/{buyerId}/activate',
  deactivateCustomer: '/px-api-gateway/account/buyer/{buyerId}/deactivate',
  // get my buyers
  getMyCustomer: '/px-api-gateway/account/my-buyers',
  // search customer
  getCustomerSearch: '/px-api-gateway/account/search-buyers-by-user?username={username}',
  // get customer settings
  getCustomerSettings: '/px-api-gateway/account/settings',
  // sales rep read
  getSalesRep: '/px-api-gateway/account/salesrep/{salesRepId}',
  getSalesReps: '/px-api-gateway/account/salesreps',
  // buyer import export urls
  exportCustomers: '/px-api-gateway/account/buyers/export',
  customerImport: '/px-api-gateway/account/buyers/import-file',
  customerImportAccept: '/px-api-gateway/account/buyers/import-file/accept',
  // buyer address export import urls
  // users import export urls
  exportCustomerUsers: '/px-api-gateway/account/buyers/users/export',
  customerUserImport: '/px-api-gateway/account/buyers/users/import-file',
  customerUserImportAccept: '/px-api-gateway/account/buyers/users/import-file/accept',
  // crud customer address
  // customer permissions
  getShoppingChannels: '/px-api-gateway/account/buyer/{buyerId}/additional-channels',
  setShoppingChannels: '/px-api-gateway/account/buyer/{buyerId}/additional-channels',
  getPaymentMethods: '/px-api-gateway/account/buyer/{buyerId}/paymentmethods',
  setPaymentMethods: '/px-api-gateway/account/buyer/{buyerId}/paymentmethods',
  setShippingMethods: '/px-api-gateway/account/buyer/{buyerId}/shippingMethods',
  // customer documents
  getDocuments: '/px-api-gateway/account/buyer/{buyerId}/documents',
  createDocument: '/px-api-gateway/account/buyer/{buyerId}/document/upload/details',
  deleteDocument: '/px-api-gateway/account/buyer/{buyerId}/document/delete/{documentKey}',
  downloadDocument: '/px-api-gateway/account/buyer/{buyerId}/document/URL/{documentKey}',
  // users
  getUsers: '/px-api-gateway/account/buyer/{buyerId}/users',
  createUser: '/px-api-gateway/account/buyer/{buyerId}/user',
  updateUser: '/px-api-gateway/account/buyer/{buyerId}/user/{userId}',
  resetPasswordUsers: '/px-api-gateway/account/buyer/{buyerId}/user/{userId}/resetPassword',
  deactivateUsers: '/px-api-gateway/account/buyer/{buyerId}/user/{userId}/deactivate',
  activateUsers: '/px-api-gateway/account/buyer/{buyerId}/user/{userId}/activate',
  // >> offer clearing apis >>
  // get all offers
  getOffers:
    '/px-api-gateway/stocklist/offerclearing/offers?startDateTime={startDateTime}&endDateTime={endDateTime}',
  // accept one offer
  acceptOffer: '/px-api-gateway/stocklist/offerclearing/offer/{offerId}/accept',
  rejectOffer: '/px-api-gateway/stocklist/offerclearing/offer/{offerId}/reject',
  counterOffer: '/px-api-gateway/stocklist/offerclearing/offer/{offerId}/counter',
  // bulk offer accept/reject/counter
  bulkAcceptOffer: '/px-api-gateway/stocklist/offerclearing/offer/bulkaccept',
  bulkRejectOffer: '/px-api-gateway/stocklist/offerclearing/offer/bulkreject',
  bulkCounterOffer: '/px-api-gateway/stocklist/offerclearing/offer/bulkcounter',
  // pricing apis
  // settings
  pricingSettingsGet: '/px-api-gateway/stocklist/pricing/settings',
  // get pricing
  pricingGet:
    '/px-api-gateway/stocklist/pricing/items?startDateTime={startDateTime}&endDateTime={endDateTime}',
  // update pricing
  pricingUpdate: '/px-api-gateway/stocklist/pricing/item/{itemNumberWarehouse}',
  // publish draft
  pricingPublish: '/px-api-gateway/stocklist/pricing/publish-draft',
  // reject draft
  pricingReject: '/px-api-gateway/stocklist/pricing/reject-draft',
  // submit draft for approval
  pricingApproval: '/px-api-gateway/stocklist/pricing/submit-for-approval',
  // import/export
  pricingExport: '/px-api-gateway/stocklist/pricing/exportitems',
  pricingImport: '/px-api-gateway/stocklist/pricing/import-pricing-file',
  pricingImportAccept: '/px-api-gateway/stocklist/pricing/accept-import',
  pricingImportCancel: '/px-api-gateway/stocklist/pricing/cancel-import',
  // stock list
  stockListExport: '/px-api-gateway/stocklist/export',
  stockListSettings: '/px-api-gateway/stocklist/stocklist/settings',
  stockListGet: '/px-api-gateway/stocklist/items',
  // sales order management
  orderSettings: '/px-api-gateway/sales-order/settings',
  orderImport: '/px-api-gateway/tenant-sales-order/import-sales-order-updates-file',
  orderImportAccept: '/px-api-gateway/tenant-sales-order/import-sales-order-updates-file/accept',
  getOrders: '/px-api-gateway/tenant-sales-orders',
  orderOptions: '/px-api-gateway/tenant-sales-orders/order-options',
  getOrder: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}',
  orderCancel: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/cancel',
  orderQtyUpdate:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/item/{itemNumberWarehouse}',
  orderQtyDelete: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/remove-items',
  orderAdditionalServices:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-additional-services',
  orderShippingOptions: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-shipping',
  orderBillingAndPaymentOptions:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-billing-and-payment',
  shippingMethod: '',
  paymentStatus: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-payment-status',
  fullFillmentStatus:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-fulfillment-status',
  bulkOrderCancel: '/px-api-gateway/tenant-sales-orders/bulk-cancel',
  orderLineItemUpdate:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/item/{itemNumberWarehouse}',
  warehouseStocklist: '/px-api-gateway/stocklist/items/warehouse/{warehouse}',
  orderAddStocklist: '/px-api-gateway/stocklist/items/warehouses',
  orderLineItemAdd: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/add-items',
  orderRelease: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/release',
  orderEmailNotification: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/notify-buyer',
  createOrder: '/px-api-gateway/tenant-sales-orders',
  updateFees: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-fees',
  // demand data
  stocklistDemandData:
    '/px-api-gateway/stocklist/analytics/demanddata/{itemNumberWarehouse}?startDateTime={startDateTime}&endDateTime={endDateTime}',
  offersActivityData:
    '/px-api-gateway/stocklist/analytics/offeractivity/{offerId}?itemNumberWarehouse={itemNumberWarehouse}',
  // order export import urls
  exportImeis: '/px-api-gateway/tenant-sales-orders/export-imei-lists',
  exportImeiTemplate: '/px-api-gateway/tenant-sales-orders/download-imei-template',
  exportOrders: '/px-api-gateway/tenant-sales-orders/export-orders',
  exportOrdersDetail: '/px-api-gateway/tenant-sales-orders/export-order-details',
  exportOrder: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/export-order',
  exportInvoice:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/invoice/{invoiceNumber}/export',
  exportOrderImeis: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/export-imei-list',
  importImeis: '/px-api-gateway/tenant-sales-order/import-imei-file',
  discardImeiImport: '/px-api-gateway/tenant-sales-order/import-imei-file/{draftId}/discard',
  acceptImeiImport: '/px-api-gateway/tenant-sales-order/import-imei-file/{draftId}/accept',
  //Invoice Shipment
  invoiceShipment: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/invoice-shipment',

  //clients
  getClients: '/billing/clients',
  getClientDetails: '/billing/client/{id}/payment-methods',
  updateDefaultPayment: '/billing/client/{id}/default-payment-method',
  clientContactDetails: '/billing/client/{id}/contact',
  downloadClient: '/billing/clients/export',

  //invoice
  getInvoices: '/billing/client/{tenantId}/invoices',
  getAllInvoices: '/billing/invoices',
  getInvoiceDetails: '/billing/invoice/{invoiceNumber}',
  updateInvoiceStatus: '/billing/invoices/batch-status-update',
  invoicePreview: '/billing/invoice/{invoiceNumber}/export',
  downloadInvoicesAsZip: '/billing/invoices/zip/export',
  downloadInvoicesAsxlsx: '/billing/invoices/xlsx/export',
  retryCardPayment: '/billing/invoice/{invoiceNumber}/retry-payment',
  bulkRetryPayment: '/billing/invoices/batch-retry-payment',

  //billing period orders
  getBillingPeriodOrders: '/billing/invoice/{invoiceNumber}/billing-period-orders',

  //contract terms
  getClientContractTerms: '/billing/client/{tenantId}/contract-terms',
  downloadContract: '/billing/client/{tenantId}/contract/{id}/export',
  updateTax: '/billing/client/{tenantId}/update-tax',
  downloadPaymentTerms: '/billing/client/{tenantId}/payment-terms/{id}/export',
  updateOfflineServiceRate: '/billing/client/{tenantId}/offline-service-rates',

  //onboarding reseller
  resellerOnboarding: '/pxnr/onboarding',

  //reports
  reports: '/billing/reports',
  reportsById: '/billing/report/{id}',

  //status check
  adminStatus: '/admin-status',

  //pxnr-sync
  pxnrSync: '/network/reseller/sync',

  //client-forms
  getProspectiveTenants: '/client-success/prospective-tenants',
  updateProspectiveTenantStatus: '/client-success/prospective-tenants/{submittedId}/status-update',
  getProsPectivetenant: '/client-success/prospective-tenants/{submittedId}',
  createNewProspectivetenant: 'client-success/prospective-tenant',

  // auctions
  auctionOnboarding: '/auction/onboarding',

  // po
  poOnboarding: '/po/onboarding',

  //cache-management
  getRedisCache: '/redis/keys',
  evictRedisCache: '/redis/evict-cache',

  //translation-automation
  getTranslationSheetValues: '/translation/sheet',
  getBitbucketRepos: '/translation/repos',
  getSheetInfo: '/translation/sheetInfo',
  getTenantLanguages: '/translation/tenantLanguages',
  // syncTenantsAndTranslationKeys: '/translation/syncTenantsAndTranslateKeys',
  syncTenants:'/translation/syncTenants',
  translateNewKeys:'/translation/syncNewKeysAndTranslate',
  translateNewTenants:'/translation/translateNewTenants',
  updateI18nTable: '/translation/updateI18nTable',
};

export const adminScreenURLs = {
  dashboard: '/dashboard',
  configTenant: '/config-tenant',
  newTenant: '/new-tenant',
  configDeployment: '/config-deployment',
  versionToRelease: '/config-deployment/version-to-release',
  testToVersion: '/config-deployment/test-to-version',
  releaseToProd: '/config-deployment/release-to-prod',
  configSchema: '/config-schema"',
  configSchemaMaster: '/config-schema/master',
  configSchemaMasterManualUpload: '/config-schema/master/manual-upload',
  configSchemaMasterHorizontalSync: '/config-schema/master/horizontal-sync',
  configSchemaMasterVerticalSync: '/config-schema/master/vertical-sync',
  configSchemaSlave: '/config-schema/slave',
  configSchemaSlaveManualUpload: '/config-schema/slave/manual-upload',
  configSchemaSlaveHorizontalSync: '/config-schema/slave/horizontal-sync',
  configSchemaSlaveVerticalSync: '/config-schema/slave/vertical-sync',
  updateVersion: '/update-version',
  buyerDecrypt: '/buyer-decrypt',
  decommission: '/decommission',
  resellerOnboarding: '/reseller-onboarding',
  syncSettings: '/sync-settings',
  translationAutomation: `/translation-automation`,
};

export const clientSuccessURLS = {
  clientForms: '/client-form',
  clientForm: 'client-form/:id',
};

// method format
export const formatString = (str: string, arg: any) => {
  return str.replace(new RegExp('{[a-z]+}', 'gi'), (match: string, index) => {
    const key = match.slice(1, match.length - 1);
    if (specialSymbols[arg[key]]) return specialSymbols[arg[key]];
    return arg[key];
  });
};

export default apiToUrlMap;
